import crypto from 'crypto';
var _verificationCode = '';

function createVerificationHash(code) {
  return crypto.createHash('sha256').update(code).digest('hex');
}

export default {
  /**
   * 校验手机验证码是否正确
   * @param phoneNumber
   * @param code
   * @returns {boolean}
   */
  verifyCode: function verifyCode(phoneNumber, code) {
    var verificationCode = phoneNumber + '#' + code;
    return this.getVerificationCode() === createVerificationHash(verificationCode);
  },

  /**
   * Get _verificationCode
   * @returns {*}
   */
  getVerificationCode: function getVerificationCode() {
    return _verificationCode;
  },

  /**
   * Set _verificationCode
   * @param code
   */
  setVerificationCode: function setVerificationCode(code) {
    _verificationCode = code;
  }
};