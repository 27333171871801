var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dashboard-editor-container",
      staticStyle: { padding: "15px 15px" }
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 0 } },
        [
          _c(
            "el-col",
            { staticClass: "padding-bottom-sm" },
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c("el-form-item", [
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "filter-item dialog-search",
                            attrs: {
                              size: "small",
                              placeholder: _vm.$t("agents.inputAgentAccount"),
                              "suffix-icon": "el-icon-search",
                              "on-icon-click": _vm.handleSearchIconClick
                            },
                            model: {
                              value: _vm.searchInput,
                              callback: function($$v) {
                                _vm.searchInput = $$v
                              },
                              expression: "searchInput"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                icon: "el-icon-search"
                              },
                              on: { click: _vm.handleSearchIconClick },
                              slot: "append"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      attrs: { prop: "type" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(_vm.agents.selectAgentType)
                          },
                          model: {
                            value: _vm.agentType,
                            callback: function($$v) {
                              _vm.agentType = $$v
                            },
                            expression: "agentType"
                          }
                        },
                        _vm._l(_vm.agentTypes || [], function(item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: {
                              "v-if": item.show,
                              label: _vm.$t(item.dec),
                              value: item.value
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 0 } },
        [
          _c(
            "el-col",
            { staticClass: "padding-bottom-sm" },
            [_c("el-form", { attrs: { inline: true } })],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.agents.data, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "id", label: _vm.$t("agents.id"), "min-width": "80" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "username",
              label: _vm.$t("agents.account"),
              "min-width": "140"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$t("agents.registerTime"),
              "min-width": "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formattedDate(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "lastLoginTime",
              label: _vm.$t("agents.lastTime"),
              "min-width": "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formattedDate(scope.row.lastLoginTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "diamondCount",
              label: _vm.$t("agents.diamondCount"),
              "min-width": "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "coinCount",
              label: _vm.$t("agents.coinCount"),
              "min-width": "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: _vm.$t("agents.operate"),
              "min-width": "300"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openRecharge(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("agents.recharge")) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openCharge(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("agents.chargeRecord")) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.chargeCard(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("agents.chargeCard")) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openSellCard(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("agents.sellCard")) +
                            "\n        "
                        )
                      ]
                    ),
                    !scope.row.isActive
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "small" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.activeAgent(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("agents.activate")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    scope.row.isActive
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "small" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.unactiveAgent(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("agents.unactive")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.agents.currentPage,
          "page-count": _vm.agents.totalPages,
          layout: "prev, pager, next, jumper",
          total: _vm.agents.totalCount
        },
        on: {
          "update:currentPage": function($event) {
            return _vm.$set(_vm.agents, "currentPage", $event)
          },
          "update:current-page": function($event) {
            return _vm.$set(_vm.agents, "currentPage", $event)
          },
          "current-change": _vm.handleCurrentChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("agents.chargeRecord"),
            visible: _vm.dialogPayListVisible,
            "modal-append-to-body": false,
            size: "large"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPayListVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.chargeList,
                border: "",
                "show-summary": true,
                "summary-method": _vm.getSummaries
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "outId",
                  label: _vm.$t("agents.outId"),
                  "min-width": "140"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "inId",
                  label: _vm.$t("agents.inId"),
                  "min-width": "140"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "cardType",
                  label: _vm.$t("agents.operateType"),
                  "min-width": "140"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getOperateType(scope.row.cardType)))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  property: "count",
                  prop: "count",
                  label: _vm.$t("agents.count")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "toBeforeCount",
                  label: _vm.$t("agents.chargeBefore")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "toAfterCount",
                  label: _vm.$t("agents.chargeAfter")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "chargeTime",
                  label: _vm.$t("agents.chargeTime"),
                  "min-width": "200"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formattedDate(scope.row.chargeTime))
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("agents.chargeCard"),
            visible: _vm.dialogChargeVisible,
            "modal-append-to-body": false,
            size: "large"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogChargeVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.chargeCardList,
                border: "",
                "show-summary": true,
                "summary-method": _vm.getSummaries
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "outId",
                  label: _vm.$t("agents.outId"),
                  "min-width": "140"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "inId",
                  label: _vm.$t("agents.inId"),
                  "min-width": "140"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "type",
                  label: _vm.$t("agents.operateType"),
                  "min-width": "140"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getOperateType(scope.row.cardType)))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { property: "count", label: _vm.$t("agents.count") }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "toBeforeCount",
                  label: _vm.$t("agents.chargeBefore")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "toAfterCount",
                  label: _vm.$t("agents.chargeAfter")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "chargeTime",
                  label: _vm.$t("agents.chargeTime"),
                  "min-width": "200"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formattedDate(scope.row.chargeTime))
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t(_vm.agents.sellCard),
            visible: _vm.dialogUnchargeVisible,
            "modal-append-to-body": false,
            size: "large"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogUnchargeVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.unchargeList.data,
                border: "",
                "show-summary": true,
                "summary-method": _vm.getUnchargSummaries
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "orderId",
                  label: _vm.$t("agents.orderId"),
                  "min-width": "220"
                }
              }),
              _c("el-table-column", {
                attrs: { property: "inId", label: _vm.$t("agents.playerId") }
              }),
              _c("el-table-column", {
                attrs: { property: "count", label: _vm.$t("agents.count") }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "toBeforeCount",
                  label: _vm.$t("agents.chargeBefore")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "toAfterCount",
                  label: _vm.$t("agents.chargeAfter")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  property: "chargeTime",
                  label: _vm.$t("agents.chargeTime"),
                  "min-width": "120"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formattedDate(scope.row.chargeTime))
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.unchargeList.currentPage,
              "page-count": _vm.unchargeList.totalPages,
              layout: "prev, pager, next, jumper",
              total: _vm.unchargeList.totalCount
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.unchargeList, "currentPage", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.unchargeList, "currentPage", $event)
              },
              "current-change": _vm.handleUnchargeCurrentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("agents.recharge"),
            visible: _vm.dialogRechargeVisible,
            "modal-append-to-body": false,
            size: "tiny"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogRechargeVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.formData,
                rules: _vm.ruleCheck,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "id", label: _vm.$t("agents.id") } },
                [
                  _c("el-input", {
                    attrs: { hidden: "", type: "text", readonly: "" },
                    model: {
                      value: _vm.formData.id,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "id", $$v)
                      },
                      expression: "formData.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "username", label: _vm.$t("agents.account") }
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", readonly: "" },
                    model: {
                      value: _vm.formData.username,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "username", $$v)
                      },
                      expression: "formData.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "type", label: _vm.$t("agents.chargeType") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("agents.selectChargeType") },
                      model: {
                        value: _vm.formData.type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type"
                      }
                    },
                    _vm._l(_vm.types || [], function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          "v-if": item.show,
                          label: _vm.$t(item.dec),
                          value: item.value
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "count", label: _vm.$t("agents.count") } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.formData.count,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "count", _vm._n($$v))
                      },
                      expression: "formData.count"
                    }
                  })
                ],
                1
              ),
              _c("el-form-item", [
                _c(
                  "button",
                  {
                    staticClass: "el-button el-button--primary",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.handleSubmit("formData")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("agents.recharge")) +
                        "\n        "
                    )
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "el-button el-button--default margin-left-sm",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.handleReset("formData")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("agents.reset")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }