import "core-js/modules/es6.regexp.to-string";
import * as CryptoJS from 'crypto-js';
var key = CryptoJS.enc.Utf8.parse('This is a key456');
var iv = CryptoJS.enc.Utf8.parse('This is an IV123');
var JsonFormatter = {
  stringify: function stringify(cipherParams) {
    // create json object with ciphertext
    var jsonObj = {
      ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)
    }; // optionally add iv and salt

    if (cipherParams.iv) {
      jsonObj.iv = cipherParams.iv.toString();
    }

    if (cipherParams.salt) {
      jsonObj.s = cipherParams.salt.toString();
    } // stringify json object


    return JSON.stringify(jsonObj);
  },
  parse: function parse(jsonStr) {
    // parse json string
    var jsonObj = JSON.parse(jsonStr); // extract ciphertext from json object, and create cipher params object
    // @ts-ignore

    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct)
    }); // optionally extract iv and salt

    if (jsonObj.iv) {
      cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
    }

    if (jsonObj.s) {
      cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
    }

    return cipherParams;
  }
};
export default {
  encode: function encode(content) {
    var options = {
      iv: iv,
      asBytes: true,
      format: JsonFormatter
    };
    var crypted = CryptoJS.AES.encrypt(content, key, options);
    return crypted.toString();
  },
  decode: function decode(text) {
    var data = JSON.parse(text);
    var decryptIv = CryptoJS.enc.Hex.parse(data.iv);
    var options = {
      iv: decryptIv,
      asBytes: true,
      format: JsonFormatter
    };
    var decrypted = CryptoJS.AES.decrypt(text.toString(), key, options); // convert to Utf8 format unmasked data

    return CryptoJS.enc.Utf8.stringify(decrypted);
  }
};