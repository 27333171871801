import _typeof from "/home/mdagsh/tools/tools/frontend/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";
export default {
  /**
   * 编码
   * @param content
   * @returns {*}
   */
  encodeData: function encodeData(content) {
    // 参数为对象时
    if (content && _typeof(content) === 'object') {
      var jsonStr = JSON.stringify(content);
      return window.btoa && window.btoa(jsonStr);
    } else if (content && typeof content === 'string') {
      // 参数为字符串时
      var obj = {
        content: content,
        date: '' + new Date().getTime()
      };
      return window.btoa && window.btoa(JSON.stringify(obj));
    } else if (content && typeof content === 'function') {
      // 参数为函数时不做处理
      return '';
    } else {
      var _obj = {
        content: '' + content,
        date: '' + new Date().getTime()
      };
      return window.btoa && window.btoa(JSON.stringify(_obj));
    }
  },

  /**
   * 解码
   * @param content
   * @returns {*|string}
   */
  decodeData: function decodeData(content) {
    var result = '';

    try {
      result = window.atob && window.atob(content);
      return JSON.parse(result).content;
    } catch (e) {
      return result;
    }
  }
};