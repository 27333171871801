import { Message } from 'element-ui';
export default {
  showErrorCode: function showErrorCode(errorCode) {
    var message = '';

    switch (errorCode) {
      case 1001:
        message = '未找到该游戏玩家';
        break;

      case 3001:
        message = '未找到该账号';
        break;

      case 3002:
        message = '密码错误';
        break;

      default:
        message = '出现错误: ' + errorCode;
        break;
    }

    Message({
      message: message,
      type: 'warning',
      duration: 2000
    });
  }
};